import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  percent: -1,
  titleMessage: "",
  showNotice: false,
  showStepEnd: false,
  minPercent: 0,
  urlDownload: "",
  fileImportResult: {},
}

export const noticeSlice = createSlice({
  name: "noticeCreateRequest",
  initialState,
  reducers: {
    changePercent: (state, action) => {
      state.percent = action.payload
    },
    changeTitleMessage: (state, action) => {
      state.titleMessage = action.payload
    },
    showNoticeCreate: (state, action) => {
      state.showNotice = action.payload
    },
    setShowStepEnd: (state, action) => {
      state.showStepEnd = action.payload
    },
    setMinPercent: (state, action) => {
      state.minPercent = action.payload
    },
    setUrlDownload: (state, action) => {
      state.urlDownload = action.payload
    },
    setFileImportResult: (state, action) => {
      state.fileImportResult = action.payload
    },
  },
})

export const {
  changePercent,
  changeTitleMessage,
  showNoticeCreate,
  setShowStepEnd,
  setMinPercent,
  setUrlDownload,
  setFileImportResult,
} = noticeSlice.actions

export default noticeSlice.reducer

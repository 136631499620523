import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  dataSigned: [],
  dataListSigned: [],
}

export const dataSignSlice = createSlice({
  name: "dataSign",
  initialState,
  reducers: {
    setDataSigned: (state, action) => {
      state.dataSigned = action.payload
    },
    setListDataSigned: (state, action) => {
      state.dataListSigned = action.payload
    },
  },
})

export const { setDataSigned, setListDataSigned } = dataSignSlice.actions

export default dataSignSlice.reducer

export const apiGetAllByRole = "Dossier/GetAllByRole"
export const apiInsert = "Dossier/Insert"
export const apiGetDetail = "Dossier/GetDetail"
export const apiUpdate = "Dossier/Update"
export const apiDelete = "Dossier/Delete"
export const apiGetListHistoryDossier = "Dossier/GetListHistoryDossier"
export const apiGetAllForUser = "Dossier/GetAllForUser"

export const apiGetAll = "Dossier/GetAll"
export const apiGetAllBrandName = "Dossier/GetAllBrandName"
export const apiSendDossier = "Dossier/SendDossier"
export const apiSendProposeDossier = "Dossier/SendProposeDossier"
export const apiRecallSendDossier = "Dossier/RecallSendDossier"
export const apiApproveDossier = "Dossier/ApproveDossier"
export const apiRefuseApproveDossier = "Dossier/RefuseApproveDossier"
export const apiRecallAproveDossier = "Dossier/RecallAproveDossier"
export const apiExpertiseDossier = "Dossier/ExpertiseDossier"
export const apiRefuseExpertiseDossier = "Dossier/RefuseExpertiseDossier"
export const apiRecallExpertiseDossier = "Dossier/RecallExpertiseDossier"
export const apiRecallPromulgateDossier = "Dossier/RecallPromulgateDossier"
export const apiPromulgateDossier = "Dossier/PromulgateDossier"
export const apiRecallDossier = "Dossier/RecallDossier"
export const apiRenewDossier = "Dossier/RenewDossier"
export const apiRecallRefuseDossier = "Dossier/RecallRefuseDossier"
export const apiAcceptDossier = "Dossier/AcceptDossier"
export const apiRefuseDossier = "Dossier/RefuseDossier"
export const apiPassDossier = "Dossier/PassDossier"
export const apiRequestAdditionalDossier = "Dossier/RequestAdditionalDossier"
export const apiReturnFromUserDossier = "Dossier/ReturnFromUserDossier"
export const apiReturnDossier = "Dossier/ReturnDossier"
export const apiRefuseReturnDossier = "Dossier/RefuseReturnDossier"
export const apiCommentDossier = "Dossier/CommentDossier"
export const apiGetAllTrackingByRole = "Dossier/GetAllTrackingByRole"

export const apiChangInforDossier = "Dossier/ChangInforDossier"
export const apiReIssueDossier = "Dossier/ReIssueDossier"

export const apiPromulgateRecallDossier = "Dossier/PromulgateRecallDossier"
export const apiPromulgateRenewDossier = "Dossier/PromulgateRenewDossier"
export const apiImportData = "Dossier/ImportData"
export const apiImportDataForNerwork = "Dossier/ImportDataForNerwork"
export const apiAdditionalDossier = "Dossier/AdditionalDossier"
export const apiPromulgateReIssiueDossier = "Dossier/PromulgateReIssiueDossier"
export const apiAcceptChangInforDossier = "Dossier/AcceptChangInforDossier"
export const apiViewDossierOld = "Dossier/ViewDossierOld"
export const apiViewCertificate = "Dossier/ViewCertificate"
export const apiExportData = "Dossier/ExportDossierData"
export const apiExportBrandNameData = "Dossier/ExportBrandNameData"
export const apiRecallPassDossier = "Dossier/RecallPassDossier"
export const apiGetCountDossier = "Dossier/GetCountDossier"
export const apiGetDigitalSignatores = "SignatureInfo/GetDigitalSignatores"
export const apiGetSignatores = "SignatureInfo/GetSignatores"
export const apiSignFile = "SignatureInfo/SignFile"
export const apiSaveCustomerSignatureInfor = "Dossier/SaveCustomerSignatureInfor"